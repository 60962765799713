// font imports
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Serif:ital,opsz,wght@0,8..144,100..900;1,8..144,100..900&display=swap");

// colors
$aj-primary: #da1f26;
$aj-gray: #f6f6f6;
$aj-gray-100: #e6e6e6;
$aj-gray-200: #cccccc;
$aj-gray-300: #b3b3b3;
$aj-gray-400: #666666;
$aj-gray-500: #232323;

:root {
  --aj-primary: #da1f26;

  --aj-gray: #f6f6f6;
  --aj-gray-25: rgba(217, 217, 217, 0.25);
  --aj-gray-100: #e6e6e6;
  --aj-gray-200: #cccccc;
  --aj-gray-300: #b3b3b3;
  --aj-gray-400: #666666;
  --aj-gray-500: #232323;

  --aj-white: #ffffff;
  --aj-white-100: #f5f5f5;
  --aj-white-200: #fff6f7;
  --aj-dark: #111111;

  --aj-light-dark-shadow: rgba(8, 15, 52, 0.06);

  --aj-border-color-light: #fff1f2;

  //
  --aj-green: #2ac16a;
  --aj-purple-100: #8567c7;
  --aj-purple-800: #702dff;
  --aj-blue: #3693ff;
  --aj-yellow: #ffcc00;

  --aj-warning: var(--aj-yellow);

  --aj-transparent: transparent;

  --aj-red-shade: #faeced;
  --aj-orange-shade: #ffe6de;
  --aj-purple-shade: #f1eaff;
  --aj-green-shade: #d1fce3;
  --aj-blue-shade: #d8eaff;
}

// functions
@mixin fontStyle($font, $size, $style, $wight, $lineHeight, $letterSpacing: 0) {
  font-family: $font, sans-serif;
  font-size: $size;
  font-style: $style;
  font-weight: $wight;
  line-height: $lineHeight;
  letter-spacing: $letterSpacing;
}

@mixin buttonBase() {
  @include fontStyle("Inter", 1.143rem, normal, 500, 100%, -0.032px);
}

// styles
*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  transition: all 300ms ease-in-out;
  font-family: "Inter", sans-serif;
}

html {
  font-size: 14px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  @media (max-width: 580px) {
    font-size: 14px;
  }
  @media (max-width: 580px) {
    font-size: 12px;
  }
}
body {
  @include fontStyle("Inter", 1.143rem, normal, 400, 175%);
}
button,
[role="button"] {
  color: var(--aj-primary);
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;

  &:focus-visible {
    box-shadow: 0 0 5px 5px var(--aj-red-shade);
  }
}
input {
  &:focus-visible {
    box-shadow: 0 0 5px 5px var(--aj-red-shade);
  }
}
.aj-container_lg {
  width: min(1248px, 100%);
  padding: 0 24px;
  margin: 96px auto;

  @media (max-width: 580px) {
    margin: 40px auto;
  }
}
.aj-container_fluid {
  width: min(1440px, 100%);
  margin: 96 auto;
  @media (max-width: 580px) {
    margin: 40px auto;
  }
  & > .content {
    width: min(1248px, 100%);
    padding: 0 24px;
    margin: auto;
  }
}
.large-font {
  @include fontStyle("Inter", 5.286rem, normal, 700, 91.892%, -0.148px);
  @media (max-width: 580px) {
    @include fontStyle("Inter", 2.5rem, normal, 700, 91.892%, -0.148px);
  }
}
h1,
.aj-h1 {
  @include fontStyle("Inter", 4.571rem, normal, 700, 106.25%, -0.128px);
}
h2,
.aj-h2 {
  @include fontStyle("Inter", 2.857rem, normal, 500, 110%, -0.08px);
}
h3,
.aj-h3 {
  @include fontStyle("Inter", 2.286rem, normal, 500, 112.5%, -0.064px);
}
h4,
.aj-h4 {
  @include fontStyle("Inter", 1.43rem, normal, 600, 120%);
}
.aj-subtitle {
  @include fontStyle("Inter", 1.143rem, normal, 600, 125%);
}
.aj-label1 {
  @include fontStyle("Inter", 1rem, normal, 500, 128.571%);
}
.aj-label2 {
  @include fontStyle("Inter", 0.857rem, normal, 500, 133.33%);
}

.aj {
  &-font {
    &-inter {
      @include fontStyle("Inter", 1rem, normal, 400, 100%);
    }
    &-roboto {
      @include fontStyle("Roboto Serif", 1rem, normal, 400, 100%);
    }
  }
  &-text {
    &-primary {
      color: var(--aj-primary);
    }
  }
  &-btn {
    &-primary {
      // @include buttonBase();
      background-color: var(--aj-primary);
      padding: 12px 16px;
      display: flex;
      line-height: 100%;
      gap: 8px;
      color: #fff;
      border-radius: 100px;
      border: none;
      outline: none;
      text-decoration: none;
      text-align: center;
      &-2 {
        @include buttonBase();
        background-color: var(--aj-primary);
        padding: 8px 16px;
        display: flex;
        gap: 8px;
        color: #fff;
        border-radius: 100px;
        border: none;
        outline: none;
        text-decoration: none;
        text-align: center;
      }
    }
    &-secondary {
      &-outline {
        // @include buttonBase();
        border-radius: 100px;
        outline: none;
        line-height: 100%;
        background-color: transparent;
        border: 1px solid var(--aj-border-color-light);
        color: var(--aj-primary);
        padding: 12px 16px;
        display: flex;
        gap: 8px;
        text-decoration: none;
      }
    }
    &-territory {
      // @include buttonBase();
      background-color: var(--aj-red-shade);
      padding: 8px 16px;
      display: flex;
      gap: 8px;
      color: var(--aj-primary);
      border-radius: 100px;
      border: none;
      outline: none;
      text-decoration: none;
    }
  }
}
